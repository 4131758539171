<template>
  <!-- 服务与支持 -->
  <div id="service">
    <!--  内容  -->
    <div class="content">
      <!--  服务能力  -->
      <div class="service_ability">
        <div class="title">
          <h3 id="ability">项目咨询</h3>
          <!-- <p class="title_en">Project Consulting</p> -->
        </div>
        <div class="service_ability_content">
          请填写以下表单，我们的销售代表会尽快与您联系
        </div>
      </div>
      <div class="form-box">
        <div class="tips"><span>*</span>为必填项</div>
        <el-form
          ref="form"
          :model="formInline"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="姓名" prop="username">
            <el-input v-model="formInline.username" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="工作邮箱" prop="email">
            <el-input v-model="formInline.email" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="formInline.phone" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="城市" prop="city">
            <el-input v-model="formInline.city" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="公司" prop="company">
            <el-input v-model="formInline.company" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="行业" prop="industry">
            <el-input v-model="formInline.industry" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="职业" prop="position">
            <el-input v-model="formInline.position" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="预算" prop="budget">
            <el-input v-model="formInline.budget" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="需求描述" class="textarea" prop="desc">
            <el-input
              type="textarea"
              placeholder=""
              :autosize="{ minRows: 2, maxRows: 8 }"
              v-model="formInline.desc"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="rules">
          <div class="tips">
            <span>*</span
            >我愿意接受北京晓羊集团及其授权的合作伙伴为我发送北京晓羊集团的产品、解决方案或服务得相关信息。我知道我可以随时取消订阅。
          </div>
          <div>
            <el-radio v-model="radio" label="1">是</el-radio>
            <el-radio v-model="radio" label="2">否</el-radio>
          </div>
        </div>
        <div class="rules">
          <el-checkbox v-model="checked"></el-checkbox
          >我理解并同意按照北京晓羊集团<b @click="showMask">隐私保护条款</b
          >使用和传递我的个人信息。
        </div>

        <el-button
          type="primary"
          :disabled="!radio || !checked"
          class="submitForm"
          @click="submitForm"
          >提交</el-button
        >
      </div>
      <div class="mask" v-show="maskVisible">
        <div class="mask-main">
          <div class="mask-main-title">
            隐私保护条款
            <i class="el-icon-close" @click="hideMask"></i>
          </div>
          <div class="mask-main-content">
            <h2>隐私政策</h2>
            <p>
              北京晓羊科技集团有限公司（以下称“我们”或“晓羊集团”）非常注重保护客户或合作伙伴（以下称“您”）的个人信息，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的信息。
            </p>
            <p>
              <b>
                【特别提示】请您在咨询项目并填写表单信息前，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您填写表单信息并提交，即意味着您同意我们按照本隐私政策收集、使用、保存、共享和保护您的相关信息。如对本隐私政策有任何疑问，您可以通过我们的网站（<a
                  href="https://www.xiaoyanggroup.com/Domain#/home"
                  target="blank"
                  >http://www.xiaoyangedu.com</a
                >)与我们联系。</b
              >
            </p>
            <p>
              <b>
                【请您注意】，您应保证在使用我们服务中所提供的一切信息均为真实，且经过合法途径获得，如其中包含第三方个人信息，请确认您已取得该信息所有方的充分、合法的授权，一旦您在使用我们服务的过程中提供此类信息，我们将认为该类信息均为您有权处理，不存在任何违反法律、法规或侵犯任何第三方合法权利的事由/瑕疵/场景。</b
              >
            </p>
            <p>
              <b>
                本政策不适用于您通过我们的服务\产品而接入的其他第三方服务\产品(“其他第
                三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者)，具体规定请参照该第三方的隐私政策或类似声明。</b
              >
            </p>
            <ul>
              <li>
                <b>一、我们如何收集和使用您的个人信息</b> <br />
                <p>我们会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
                <ul>
                  <li>
                    1.更好的为您提供服务\产品
                    <ul>
                      <li>
                        <p>
                          (1)您向我们提供的个人信息 <br />
                          <b>为便于向您提供服务\产品，</b>
                          您需要向我们提供的联系人信息（姓名、电话、工作邮箱），所在城市、所属公司、所属行业、职业、所需产品/解决方案、预算以及对需求的具体描述，<b
                            >也可能会包含或者关联到您的个人信息，我们将对于上述信息中涉及的个人信息予以严格保护。</b
                          >
                        </p>
                      </li>
                      <li>
                        <p>
                          (2)我们在您使用服务过程中收集的信息<br />
                          为向您展示有关我们的产品、解决方案和/服务，我们会收集您在使用我们服务过程中产生的信息用于向您展示。为了满足法律法规及提供服务的基本要求，保障您更好的使用我们的服务\产品，提升问题排查效率，我们会收集您的日志信息、浏览及搜索信息、位置信息、设备信息等，问题上报描述、联系电话以及您在使用服务过程中所提交的任何文字、照片信息等，并可能将您在使用我们关联方的某项服务中提供的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的服务\产品信息。
                        </p>
                      </li>
                      <li>
                        <p>
                          (3)当您与我们联系时，我们可能会收集您的通信\通话记录和内容或您留下的联系方式信息，以便与您联系、帮助您解决问题，或记录相关问题的处理方案及结果。
                        </p>
                      </li>
                      <li>
                        <p>
                          (4)当您参与我们的推广活动和问卷调查过程中，我们可能会收集您主动提供的相关信息。
                        </p>
                      </li>
                      <li>
                        <p>(5)我们通过间接方式收集到的您的信息<br /></p>
                        <p>
                          为能向您提供更优质的服务，同时为能确认交易状态及为您提供售后与解决方案服务，我们可能会通过您选择的交易对象和交易直接涉及的第三方信息系统等收集与交易进度相关的您的交易信息，或者将您的信息共享给上述服务提供者，以便于我们满足您的需求并保证服务的顺利完成，或者更好地预防诈骗等恶意行为，我们会依据法律法规的要求以及与第三方的约定，向我们的关联方、合作伙伴提供您的有关信息。<br />
                        </p>
                        <p>
                          <b
                            >我们承诺将对于上述信息予以严格保护。
                            请注意，无法与任何特定个人直接建立联系的数据，不属于上述信息。</b
                          >
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    2.改进我们的服务\产品 <br />
                    <p>
                      为改善我们的服务或产品、向您提供个性化的信息及服务，使您及时了解丰富的产品，提升服务体验，我们会根据
                      <b
                        >您的浏览及搜索记录、设备信息、位置信息，提取您的浏览、搜索偏好、行为习惯特征，基于特征标签推送相关信息，包括通过短信、电话、邮件、线上展示各种方式向您推送产品信息、调研问卷。如果您不同意接收推送的信息，可以通过相应的退订方式进行退订。</b
                      >
                    </p>
                  </li>
                  <li>
                    3.为您提供安全保障 <br />
                    <ul>
                      <li>
                        <p>
                          (1)为了保障您的系统运行安全，满足法律法规和我们协议规则的相关要求，在您使用我们的服务\产品过程中，我们会获取您的设备信息，包括您使用的设备属性、连接和状态信息、设备
                          MAC 地址、软件列表、电信运营商等。
                        </p>
                      </li>
                      <li>
                        <p>
                          为提高您使用我们提供服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们的关联方、合作伙伴依法共享的信息，根据您的使用习惯和常用软件信息来综合判断安全风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击的安全风险以及违反我们或关联方协议、政策或规则的行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    4.请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
                    <ul>
                      <li><p>(1)与国家安全、国防安全有关的。</p></li>
                      <li>
                        <p>(2)与公共安全、公共卫生、重大公共利益有关的。</p>
                      </li>
                      <li>
                        <p>(3)与犯罪侦查、起诉、审判和判决执行等有关的。</p>
                      </li>
                      <li>
                        <p>
                          (4)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。
                        </p>
                      </li>
                      <li><p>(5)个人信息主体自行向社会公众公开的。</p></li>
                      <li>
                        <p>
                          (6)从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。
                        </p>
                      </li>
                      <li><p>(7)根据您的要求签订合同所必需的。</p></li>
                      <li>
                        <p>
                          (8)用于维护所提供的服务\产品的安全稳定运行所必需的，例如发现、处置服务\产品的故障。
                        </p>
                      </li>
                      <li>
                        <p>
                          (9)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
                        </p>
                      </li>
                      <li><p>(10)法律法规规定的其他情形。</p></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>二、我们如何使用 Cookie 和同类技术 </b>
                <ul>
                  <li>
                    <p>
                      <b>1.Cookie 的使用</b>
                      <br />
                    </p>
                    <p>
                      为确保网站正常高效运转、为您获得更轻松的访问体验，我们会在您的设备终端/系统上存储名为
                      Cookie 的小数据文件。Cookie
                      会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息的流程；帮助您优化对产品或服务的选择与互动；保护您的数据安全等。我们不会将
                      Cookie
                      用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
                      Cookie。您可以清除计算机或移动设备上保存的所有
                      Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受
                      Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝
                      Cookie。另外，您也可以清除软件内保存的所有
                      Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>2.网络 Beacon 和同类技术的使用</b>
                      <br />
                    </p>
                    <p>
                      除 Cookie
                      外，我们网页上常会包含一些电子图像(称为“单像素GIF
                      文件”或“网络
                      Beacon”)等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些
                      Cookie。我们使用网络 Beacon 的方式有:
                    </p>
                    <ul>
                      <li>
                        <p>
                          我们通过在我们网站上使用网络
                          Beacon，计算用户访问数量，并通过访问 Cookie
                          辨认注册用户。
                        </p>
                      </li>
                      <li>
                        <p>
                          我们通过得到的 Cookie
                          信息，可以在我们网站提供个性化服务。
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>三、我们如何共享、转让、公开披露您的个人信息 </b>
                <ul>
                  <li>
                    <p>1. 共享 <br /></p>
                    <p>
                      我们不会与我们网站以外的任何公司、组织、个人共享您的个人信息，但以下情况除外：
                    </p>
                    <ul>
                      <li>
                        <p>
                          (1)基于法定情形下：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求；
                        </p>
                      </li>
                      <li>
                        <p>
                          (2)与关联公司共享：为向您提供优质化的服务，我们会基于必要性的前提下将您的个人信息与我们的关联公司共享。
                        </p>
                      </li>
                      <li>
                        <p>
                          (3)与合作伙伴分享：我们可能会向业务合作伙伴共享为您提供服务所必要的信息。我们的业务合作伙伴包括合作商、第三方合作商业务合作伙伴。
                        </p>
                      </li>
                      <li>
                        <p>
                          (4)基于协议约定：依据您与我们签署的相关协议(包括在线签署的电子协议)或法律文件，有必要向第三方共享时。
                        </p>
                      </li>
                      <li>
                        <p>
                          (5)基于合理商业习惯：在与其他公司进行资本市场(包括但不限于
                          IPO，债券发行)接受尽职调查时。
                        </p>
                      </li>
                      <li>
                        <p>
                          (6)基于学术研究：为科研机构开展统计或学术研究之必要。
                        </p>
                      </li>
                      <li>
                        <p>(7)基于符合法律法规的社会公共利益。</p>
                        <b>
                          <p>
                            我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方
                            SDK
                            会有所不同，一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。
                          </p>
                          <p>
                            请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
                          </p>
                        </b>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>2. 转让</p>
                    <p>
                      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
                    </p>
                    <ul>
                      <li>
                        <p>
                          (1)获得您的明确同意后，我们会向其他方转让您的个人信息。
                        </p>
                      </li>
                      <li>
                        <p>
                          (2)在我们发生合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>3. 公开披露</p>
                    <p>我们不会公开披露您的个人信息，但以下情况除外：</p>
                    <ul>
                      <li>
                        <p>
                          (1)基于您的主动选择或获得您的明确同意，我们可能会公开您的相关个人信息。
                        </p>
                      </li>
                      <li>
                        <p>
                          (2)如果我们确定您出现违反法律法规或严重违反相关协议、规则的情况，或为保护我们及其关联公司用户或公众的人身财产安全免遭侵害以及维护良好的公平交易环境，我们可能依据法律法规或相关协议、规则披露您的相关个人信息。
                        </p>
                      </li>
                      <li>
                        <p>
                          (3)基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>四、我们如何保护您个人信息的安全</b>
                <ul>
                  <li>
                    <p>
                      1.
                      为保障您的个人信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息：使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                    </p>
                  </li>
                  <li>
                    <p>
                      2.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>五、您的个人信息如何在全球范围转移 </b>
                <p>
                  我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
                </p>
                <ul>
                  <li><p>1.法律法规有明确规定。</p></li>
                  <li><p>2.获得您的明确授权。</p></li>
                  <li>
                    <p>
                      3.您通过互联网、线下进行跨境交易等个人主动行为。 <br />
                    </p>
                    <p>
                      针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>六、通知与修订 </b>
                <br />
                <p>
                  为给您提供更好的服务/产品，我们的服务将不时变化，本隐私政策也将随之调整。我们会通过我们的网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问时关注最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的隐私政策并受之约束。
                </p>
              </li>
              <li>
                <b>七、如何联系我们 </b>
                <br />
                <p>
                  如果您对于我们的个人信息处理行为存在任何投诉举报需求，请您<b
                    >通过网站【<a
                      href="https://www.xiaoyanggroup.com/Domain#/home"
                      target="blank"
                      >http://www.xiaoyangedu.com</a
                    >】上提供的联系方式\客服系统与我们联系</b
                  >并作充分描述，我们将在验证您身份的<b>15 个工作日</b
                  >内答复您的请求并尽力解决。
                </p>
              </li>
            </ul>
          </div>
          <div class="mask-main-footer">
            <el-button type="primary" @click="hideMask()">同意</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serviceAbilityPc from '@/assets/images/service/service_ability_pc.png'
import serviceAbilityMobile from '@/assets/images/service/service_ability_mobile.png'
export default {
  name: 'ServiceSupport',
  data() {
    return {
      serviceAbilityPc,
      serviceAbilityMobile,
      subList: [],
      addressList: [],
      formInline: {
        username: '',
        email: '',
        phone: '',
        city: '',
        company: '',
        industry: '',
        position: '',
        budget: '',
        desc: '',
      },
      textarea: '',
      radio: false,
      checked: false,
      maskVisible: false,
      rules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '输入电子邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              let regex =
                /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
              if (regex.test(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的电子邮箱'))
              }
            },
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入电话',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              let regex1 =
                /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
              let regex2 = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/
              if (regex1.test(value) || regex2.test(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的电话'))
              }
            },
            trigger: 'blur',
          },
        ],
        city: [
          {
            required: true,
            message: '请输入城市',
            trigger: 'blur',
          },
        ],
        company: [
          {
            required: true,
            message: '请输入企业',
            trigger: 'blur',
          },
        ],
        industry: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        position: [{ required: true, message: '请输入职业', trigger: 'blur' }],
        budget: [{ required: true, message: '请输入预算', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入需求描述', trigger: 'blur' }],
      },
    }
  },
  methods: {
    showMask() {
      this.maskVisible = true
    },
    hideMask() {
      this.maskVisible = false
    },
    // 获取子公司列表
    // 获取办事处地址列表
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios
            .post('/basic/email', this.formInline)
            .then(res => {
              if (res.result) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  onClose: () => {
                    this.resetForm()
                  },
                })
              } else {
                this.$message({
                  message: '提交失败',
                  type: 'error',
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                message: '提交失败',
                type: 'error',
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
%service {
  // 头部banner
  .top_banner {
    position: relative;
    margin-bottom: 130px;
    img {
      width: 100%;
    }
    .top_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 42px 100px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $--color-white;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 56px;
        @include px2vw('font-size', 56);
      }
      p {
        font-size: 32px;
        @include px2vw('font-size', 32);
      }
    }
  }
  .content {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 60px;
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .service_translate {
      &_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
        img {
          @include px2vw('width', 533);
        }
        p {
          width: calc(100% - 27.7vw - 60px);
          //@include px2vw('width', 843);
          @include px2vw(' font-size', 26);
          //font-size: 26px;
          line-height: 2em;
        }
      }
    }
    .service_ability,
    .service_map {
      margin: 240px 0 80px;
      img {
        margin-top: 60px;
        width: 100%;
      }
    }
    .service_ability {
      #ability {
        font-size: 32px;
      }
      .service_ability_content {
        font-size: 16px;
        color: #666;
        text-align: center;
      }
    }
    .service_map {
      &_content {
        .service_HQ {
          span.color-primary {
            font-weight: bold;
          }
        }
        h4 {
          margin: 40px 0;
          font-size: 24px;
        }
        ul {
          margin-top: 40px;
          display: grid;
          grid-template-columns: repeat(2, 50%);
          span {
            font-weight: bold;
          }
        }
      }
    }
    .form-box {
      // width: 1200px;
      box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
      margin: 0 auto 100px;
      padding: 35px 50px 77px;
      .tips {
        span {
          color: #ff0000;
        }
      }
      .demo-form-inline {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 80px;
      }
      .el-form-item {
        margin-right: 0;
        width: 43%;
        border-bottom: 1px solid #d5d5d5;
        &:nth-child(2n) {
          margin-left: 14%;
        }
        ::v-deep .el-select {
          margin-left: 45px;
        }
      }
      ::v-deep .el-form-item__content {
        width: 84%;
      }
      ::v-deep .el-input,
      ::v-deep .el-select {
        width: 100%;
      }
      ::v-deep .el-input__inner {
        border: 0;
      }
      .three {
        width: 30%;
        ::v-deep .el-form-item__content {
          width: 70%;
        }
        ::v-deep .el-select {
          margin-left: 75px;
        }
      }
      .three_two {
        ::v-deep .el-select {
          margin-left: 15px;
        }
      }
      textarea {
        resize: none !important;
      }
      .textarea {
        border: 0;
        width: 100%;
        height: 160px;
        margin: 0 0 20px;
        // margin-bottom: 20px;
        ::v-deep .el-form-item__content {
          width: 100%;
          border: 0;
        }
        ::v-deep .el-textarea {
          height: 160px;
        }
        ::v-deep .el-textarea__inner {
          height: 100% !important;
        }
      }
      .rules {
        margin-bottom: 40px;
        div {
          :last-child {
            margin-top: 10px;
          }
        }
        ::v-deep .el-checkbox {
          margin-right: 10px;
        }
        b {
          cursor: pointer;
        }
      }
      ::v-deep .el-button {
        border-radius: 0;
        padding: 20px 20px;
      }
      .submitForm {
        width: 100%;
      }
    }
    .mask {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      left: 0;
      top: 0;
      z-index: 100;
      .el-icon-close {
        float: right;
        font-size: 20px;
        cursor: pointer;
      }
      &-main {
        width: 800px;
        height: 100%;
        background-color: $--color-white;
        margin: 0 auto;
        border-radius: 10px;
        padding: 15px;
        &-title {
          height: 30px;
          font-weight: 500;
          font-size: 18px;
          display: flex;
          align-items: center;
          position: relative;
          i {
            position: absolute;
            right: 15px;
          }
        }
        &-content {
          height: calc(100% - 110px);
          overflow: auto;
          h2 {
            text-align: center;
          }
          p {
            text-indent: 2em;
            text-align: justify;
          }
        }
        &-footer {
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

.view-pc > #service {
  @extend %service;
  .top_banner {
    margin-top: 125px;
  }
}

.view-mobile > #service {
  @extend %service;
  margin-top: 44px;
  .top_banner {
    .top_title {
      width: 100%;
      height: 100%;
      padding: 0;
      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 0 10px;
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 60px;
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 14px;
        margin-top: 0;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .service_translate {
      &_content {
        display: block;
        margin-top: 60px;
        img,
        p {
          width: 100%;
          font-size: 16px;
        }
      }
    }
    .service_ability {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .service_map {
      &_content {
        h4 {
          margin: 40px 0;
          font-size: 20px;
        }
        ul {
          margin-top: 40px;
          display: block;
          span {
            font-weight: bold;
          }
        }
      }
    }
    .form-box {
      width: calc(100% - 40px);
      padding: 35px 20px 30px;
      .el-form-item {
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid #d5d5d5;
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }
    .mask {
      &-main {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 0;
        padding: 0;
        &-title {
          padding: 15px;
        }
        &-content {
          padding: 0 15px;
          height: calc(100% - 100px);
        }
        &-footer {
          height: 40px;
          .el-button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
